
import IPermission from "@/lib/interfaces/permission";
import { isAllowed } from "@/lib/permissions";
import Vue from "vue";
import { RouteRecordPublic } from "vue-router";

export default Vue.extend({
  name: "Worganize",

  computed: {
    permissions(): Array<IPermission> {
      return this.$store.state.permissions;
    },

    availableViews(): Array<RouteRecordPublic> {
      return this.$router
        .getRoutes()
        .filter(
          (view) =>
            view.meta.indexable && isAllowed(view.meta.permission, "leitura")
        )
        .sort((a, b) => (a.meta?.order ?? 0) - (b.meta?.order ?? 0));
    },
  },
});
